import React from 'react';
import MuiLink from '@mui/material/Link';
import { GLink } from './GLink';

const Link = React.forwardRef((props, ref) => {
  const { to } = props;
  return to ? (
    <MuiLink ref={ref} component={GLink} to={to} {...props} />
  ) : (
    <MuiLink ref={ref} {...props} />
  );
});

Link.displayName = 'Link';

export { Link };
