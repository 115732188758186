import React from 'react';
import { AppBar, Toolbar, Stack, Typography } from '@mui/material';

import FallowEarthIcon from './FallowEarthIcon';

import StyledChipButton from './StyledChipButton';
// import ResponsiveChipNav from './ResponsiveChipNav';
import ScrollToChip from './ScrollToChip';

const iconOnly = false;

const Header = ({ title, ...props }) => {
  // const gradStart = hexToRgb(theme.palette.background.default + '40');
  // const gradEnd = hexToRgb(theme.palette.background.default + '00');
  return (
    <header>
      <AppBar
        sx={{
          backgroundColor: `transparent`,
          backgroundImage: 'unset',
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            {/* <Stack direction="row" alignItems="center" spacing={1}>
              <FallowEarthIcon fontSize="inherit" />
              <Typography>Fallow Earth</Typography>
            </Stack> */}
            <StyledChipButton
              clickable
              label={iconOnly ? '' : 'Fallow Earth'}
              to="/"
              aria-label="Return to homepage"
              icon={<FallowEarthIcon fontSize="inherit" />}
              sx={{
                '& .MuiChip-icon': {
                  ml: iconOnly ? 0.95 : 1,
                  // p: 0,
                },
                '& .MuiChip-label': {
                  pl: iconOnly ? 0.33 : 'auto',
                },
              }}
            />
            <nav>
              <ScrollToChip />
            </nav>
          </Stack>
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Header;
