const darkPalette = {
  mode: 'dark',
  primary: {
    main: '#1ac1a1',
    // main: '#0b5345',
  },
  secondary: {
    main: '#ff4433',
  },
  navButton: {
    main: '#faf9f6',
  },
  background: {
    // default: '#031d18',
    default: '#021713',
    dark: '#122622',
    paper: '#031d18',
  },
  error: {
    main: '#ff3131',
  },
  warning: {
    main: '#dfff00',
  },
  success: {
    main: '#0fff50',
  },
  info: {
    main: '#1f51ff',
  },
  text: {
    primary: '#faf9f6',
    secondary: 'rgba(250,249,246,0.7)',
    disabled: 'rgba(250,249,246,0.5)',
  },
  divider: 'rgba(250,249,246,0.12)',
  glass: '#0e2924',
};

export default darkPalette;
