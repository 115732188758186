const alphaToHex = (percent) => {
  if (isNaN(percent)) {
    console.log(`percent <${percent}> is not a number, returning 'ff'`);
    return 'ff';
  }
  const capped = parseInt(Math.min(Math.max(parseFloat(percent), 0), 1) * 255);
  const hex = capped.toString(16);
  return hex;
};

export default alphaToHex;
