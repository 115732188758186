import React from 'react';
import { Box } from '@mui/material';
// import { MDXProvider } from '@mdx-js/react';

import Footer from './Footer';
import Header from './Header';
import useSiteMetadata from '../hooks/useSiteMetadata';

const PageWrapper = ({ children }) => {
  const metadata = useSiteMetadata();
  return (
    <>
      <Header title={metadata.title} />
      <Box minHeight="100svh" display="flex" flexDirection="column">
        <Box flexGrow={1}>
          {/* <MDXProvider> */}
          {children}
          {/* </MDXProvider> */}
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default PageWrapper;
