import React from 'react';
import ReactDOM from 'react-dom';

import RootWrapper from './src/components/RootWrapper';
import PageWrapper from './src/components/PageWrapper';

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <PageWrapper>{element}</PageWrapper>
    </>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <RootWrapper>{element}</RootWrapper>
    </>
  );
};

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}
