import React from 'react';
import { Link } from 'gatsby';
import { Box } from '@mui/system';

const _ALink = ({ href, children, innerRef, ...other }) => (
  <a href={href} ref={innerRef} {...other}>
    {children}
    <Box
      component={'span'}
      sx={{
        // fontFamily: 'monospace',
        fontSize: '0.8em',
      }}
    >
      ↗&#xFE0E;
    </Box>
  </a>
);

export const GLink = React.forwardRef((props, ref) => {
  const { to, activeClassName, partiallyActive, ...other } = props;
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    const file = /\.[0-9a-z]+$/i.test(to);

    if (file) {
      return <_ALink href={to} innerRef={ref} {...other} />;
    }
    return (
      <Link
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        innerRef={ref}
        {...other}
      />
    );
  }
  return <_ALink href={to} innerRef={ref} {...other} />;
});

GLink.displayName = 'Link';
