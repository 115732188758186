import typography from './typography';
import lightPalette from './lightPalette';
import darkPalette from './darkPalette';

const getDesignTokens = (mode) => ({
  palette: mode === 'light' ? lightPalette : darkPalette,
  typography: typography,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          code: 'code',
        },
      },
    },
  },
});

export default getDesignTokens;
