import '@fontsource/roboto';
import '@fontsource/patua-one';
import '@fontsource/atkinson-hyperlegible/400.css';
import '@fontsource/atkinson-hyperlegible/700.css';
import '@fontsource/space-mono';

const display = [
  '"Patua One"',
  // '"Atkinson Hyperlegible"',
  // '"Roboto"',
  // '"Helvetica"',
  // '"Arial"',
  'serif',
].join(',');

const body = [
  '"Atkinson Hyperlegible"',
  // '"Roboto"',
  // '"Helvetica"',
  // '"Arial"',
  'sans-serif',
].join(',');

const monospaced = ['"Space Mono"', 'monospace'].join(',');

const typography = {
  fontFamily: body,
  fontSize: 18,
  h5: {
    fontFamily: display,
  },
  h4: {
    fontFamily: display,
  },
  h6: {
    fontFamily: display,
  },
  h3: {
    fontFamily: display,
  },
  h2: {
    fontFamily: display,
  },
  h1: {
    fontFamily: display,
  },
  button: {
    fontSize: '1rem',
  },
  code: {
    fontFamily: monospaced,
    fontSize: '0.95rem',
    paddingInline: 2,
    marginInline: 1,
  },
};

export default typography;
