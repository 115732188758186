import React, { useState, useMemo, createContext, useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';

import getDesignTokens from '../styles/getDesignTokens';

// const useResponsiveText = true;

const ColorModeContext = createContext({
  mode: '',
  toggleColorMode: () => {},
});

const RootWrapper = ({ children }) => {
  // Check whether the viewer's device prefers dark mode:
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // And set the initial color mode based on this:
  const [mode, setMode] = useState('dark');

  // useEffect(() => {
  //   setMode(prefersDarkMode ? 'dark' : 'light');
  // }, []);

  const colorMode = useMemo(
    () => ({
      mode,
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [mode]
  );

  const theme = useMemo(() => responsiveFontSizes(createTheme(getDesignTokens(mode)), [mode]));

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export default RootWrapper;

export { ColorModeContext };
