import React from 'react';
import { Container, Stack, Divider, Typography } from '@mui/material';

import useSiteMetadata from '../hooks/useSiteMetadata';

import ColorModeToggle from './ColorModeToggle';

const Footer = () => {
  const { legal } = useSiteMetadata();
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <Divider sx={{ marginX: 4 }} />
      <Container maxWidth="xs" sx={{ paddingX: 2 }}>
        {/* <Stack spacing={1}> */}
        <Divider />
        <Stack
          breakpoint="sm"
          spacing={3}
          py={6}
          // padding={(2, 0)}
          alignItems="center"
          justifyContent="space-between"
        >
          {/* <ColorModeToggle /> */}
          <Typography variant="body2" textAlign="center" fontSize="0.95rem">
            {legal}
          </Typography>
          <Typography variant="body2" textAlign="center" fontSize="0.95rem">
            © Fallow Earth {currentYear}
          </Typography>
        </Stack>
        {/* </Stack> */}
      </Container>
    </footer>
  );
};

export default Footer;
