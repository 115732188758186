import { styled } from '@mui/material';
import { ChipButton } from './GatsbyMaterialComponents';

import alphaToHex from '../utils/alphaToHex';

const alpha = 0.2;

const StyledChipButton = styled(ChipButton)(({ theme }) => ({
  backgroundColor: theme.palette.glass + alphaToHex(theme.palette.mode === 'dark' ? 0.4 : 0.2),
  backdropFilter: `blur(${theme.spacing(1.5)})`,
  boxShadow: theme.shadows[3],
  '& .MuiChip-icon': {
    color: theme.palette.text.primary,
  },
  border: `${theme.palette.text.disabled} solid 0.5px`,
}));

export default StyledChipButton;
