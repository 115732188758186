import React from 'react';

import StyledChipButton from './StyledChipButton';

const ScrollToChip = ({ label = 'Contact us', ...other }) => {
  const scrollToContact = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };
  return <StyledChipButton clickable label={label} onClick={scrollToContact} {...other} />;
};

export default ScrollToChip;
