import React from 'react';
import { GLink } from './GLink';

export default function patchButtonComponent(BaseButtonComponent) {
  return React.forwardRef((props, ref) => {
    const { to, ...buttonProps } = props;
    const component = to ? GLink : undefined;

    return <BaseButtonComponent component={component} ref={ref} to={to} {...buttonProps} />;
  });
}
