const lightPalette = {
  mode: 'light',
  primary: {
    main: '#107864',
  },
  secondary: {
    main: '#ff4433',
  },
  navButton: {
    main: 'rgba(0, 0, 0, 0.87)',
  },
  background: {
    default: '#faf9f6',
    dark: '#f5f5f2',
    paper: '#faf8f3',
  },
  error: {
    main: '#ff3131',
  },
  warning: {
    main: '#dfff00',
  },
  success: {
    main: '#0fff50',
  },
  info: {
    main: '#1f51ff',
  },
  divider: 'rgba(2,48,32,0.12)',
  glass: '#faf9f6',
};

export default lightPalette;
