import React from 'react';
import { SvgIcon } from '@mui/material';

const logoPath =
  'M20.39,4.26c-4.21,5.81-3.74,7.08,3.42,8.36,.27,.05,.25,.43-.02,.46-7.31,.69-7.6,1.91-2.79,8.15,.15,.2-.08,.47-.3,.35-7.46-4.18-9.69-4.24-17-.53-.22,.11-.43-.15-.29-.34C7.86,14.62,7.52,13.32,.19,11.86c-.27-.05-.24-.44,.03-.46,7.1-.53,7.54-1.68,2.98-7.48-.17-.21,.1-.49,.32-.33C9.47,7.94,10.69,7.45,11.35,.21c.02-.27,.42-.29,.46-.02,1.17,7.37,2.44,7.88,8.26,3.74,.21-.15,.48,.11,.32,.33Z';

const FallowEarthIcon = ({ fontSize = 'small', ...other }) => {
  //Do something here
  return (
    <SvgIcon fontSize={fontSize} {...other}>
      <path d={logoPath} />
    </SvgIcon>
  );
};

export default FallowEarthIcon;
